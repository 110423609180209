import * as Tone from 'tone/build/esm';
import SoundModule from "./SoundModuleClass";

export interface Step {
  on: boolean
}

export class StepSequencer {
  private numberOfSteps = 16; //how many steps in sequencer
  private numberOfChannels = 16; //how many notes or channels does the grid span?
  private matrix: Step[][];
  position: number = 0;
  bpm: number = 120;
  isMuted = false; //default to following global transport
  sounds: SoundModule[];
  private transportId: number | undefined;
  private updateCallback: Function = (pos: number) => { console.log('No position update callback set') };

  constructor(channels: number = 16, steps: number = 16, update: Function = (pos:number)=>{}){
    console.info("Init step sequencer with " + this.numberOfChannels + " channels and " + this.numberOfSteps + " steps.");

    this.numberOfChannels = channels;
    this.numberOfSteps = steps;
    this.updateCallback = update;
  }

  getNote = (x: number, y: number) => this.matrix[x][y];
  setNote = (x: number, y: number, value: boolean) => {
    this.matrix[x][y].on = value;
  }

  getMatrix = () => this.matrix;
  setMatrix = (matrix: Step[][]) => {
    //this.matrix = matrix.slice();
    this.matrix = matrix;
    this.config();
  }

  debugTable = () => {
    console.table(this.matrix);
  }

  setSoundModules = (sounds: SoundModule[]) => {
    this.sounds = sounds;
  }

  setAmplitudeEnvelope = (params: {attack?: number, decay?: number, sustain?: number, release?: number}) => {
    for (let sm of this.sounds) {
      sm.setAmplitudeEnvelope(params);
    }
  }

  setPortamento = (val: number) => {
    for (let sm of this.sounds) {
      sm.setPortamento(val);
    }
  }

  setOscillatorType = (val: number) => {
    for (let sm of this.sounds) {
      sm.setOscillatorTypeIndex(val);
    }
  }

  setLinearVolume = (val: number) => {
    for (let sm of this.sounds) {
      sm.setLinearVolume(val);
    }
  }

  getNumberOfChannels = () => this.numberOfChannels;
  getNumberOfSteps = () => this.numberOfSteps;

  config = () => {
    if (this.transportId !== undefined){
      //console.log("Clear sequence with ID " + this.transportId);
      Tone.Transport.clear(this.transportId);
    }
    
    //this.position = 0;
    this.transportId = Tone.Transport.scheduleRepeat(this.playNotes, this.numberOfSteps+"n");
    //console.log("Started sequence with ID " + this.transportId);
  }

  mute = () => {
    this.isMuted = !this.isMuted; //basic toggle
    //TODO: Fix mute
    // for (let sm of this.sounds){
    //   sm.instrument.set({mute: this.isMuted});
    // }
  }

  // requestPositionSync = () => {
  //   const event = new CustomEvent("syncPositionRequested", { detail: this.position });
  //   document.dispatchEvent(event);
  // }

  playNotes = (time: number) => {
      let channelsInStep: Step[] = this.matrix[(this.position+1) % this.numberOfSteps];

      for (const [index, channel] of channelsInStep.entries()){
        if (channel.on == true && !this.isMuted) { //if note is on, play it
          //console.log('Play channel ' + index + ' at ' + time);
          this.sounds[index%this.sounds.length].playAtTime(time);
        }
      }

    // const that = this;
    // Tone.Draw.schedule(function(){
    //   //this callback is invoked from a requestAnimationFrame
    //   //and will be invoked close to AudioContext time
    //   //that.updateCallback(that.position);
    //   //that.position = (that.position + 1);
  
    // }, time) //use AudioContext time of the event
    
  }

  stop = () => {
    Tone.Transport.stop();
  }
}
/*
 *  Core types
 */


export enum InstrumentTypes {
  StepSequencer,
  Drums,
  SampleLoop
}
  
export enum SynthTypes {
  Synth,
  Mono,
  FM,
  Sampler,
  Player
}

export enum EffectTypes {
  Compressor,
  Reverb,
  PingPongDelay,
  BitCrusher,
  Chorus
}
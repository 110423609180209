import React, {useState} from 'react';
import { Step } from '../../assets/js/StepSequencerClass';
import { motion } from "framer-motion";
import StepSequencerColumn from "./StepSequencerColumn";


interface ModuleProps {
  channels: number,
  baseColor: string,
  steps: number,
  monophonic?: boolean,
  matrix: Array<Array<Step>>,
  setMatrix: Function
}


const StepSequencerComponent = (props:ModuleProps) => {
  const [mouseIsDown, setMouseIsDown] = useState(false);
  const [drawMode, setDrawMode] = useState(true); //drawMode defines whether you're adding or removing notes

  const handleNoteDown = (colIndex: number, rowIndex: number) => {
    let drawMode = !props.matrix[colIndex][rowIndex].on;
    setDrawMode(drawMode);
    setNote(colIndex, rowIndex, !props.matrix[colIndex][rowIndex].on);
  }

  const handleNoteDraw = (colIndex: number, rowIndex: number) => {
    if (mouseIsDown){
      setNote(colIndex, rowIndex, drawMode);
    }
  }

  const setNote = (colIndex: number, rowIndex: number, val: boolean) => {
    let newMatrix = props.matrix.map(inner => inner.slice());
    
    //if monophonic, turn off other notes in the same column
    if (props.monophonic) {
      for (let i = 0; i < newMatrix[colIndex].length; i++){
        newMatrix[colIndex][i].on = false;
      }
    }

    newMatrix[colIndex][rowIndex].on = val;
    props.setMatrix(newMatrix);
  }

  //TODO: This is wildly inefficient with tons of unnecessary re-renders. Fix ASAP.
  return (
    <div className="flex flex-row h-full" onMouseDown={() => { setMouseIsDown(true) }} onMouseUp={() => { setMouseIsDown(false) }}>
      { props.matrix.map((col: Step[], colIndex) => {
        return <StepSequencerColumn key={'col-'+colIndex} col={col} colIndex={colIndex} onMove={handleNoteDraw} onDown={handleNoteDown} steps={props.steps} baseColor={props.baseColor} />
      })
    }
  </div>
  );
}

StepSequencerComponent.whyDidYouRender = true;
export default React.memo(StepSequencerComponent);
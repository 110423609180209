import React, {useEffect, useState, useRef} from 'react';
import * as Tone from 'tone/build/esm';
import Track from '../../assets/samples/crackle.wav';
import ColorSystem from '../../assets/js/ColorSystem';
import { subscribeKey } from 'valtio/utils'
import { store } from "../../App"
import { useSnapshot } from 'valtio';

interface ModuleProps {
  baseColor: string
}

const SampleLoopModule = (props:ModuleProps) => {
  const [isActive, setIsActive] = useState(false);
  const colorSet = new ColorSystem(props.baseColor).getColors();
  const { isPlaying } = useSnapshot(store);

  //Stop playing if global player is stopped, but don't change the status of the toggle
  useEffect(()=>{
    if (isActive) {
      if (isPlaying){
        playerRef.current!.start();
      } else {
        playerRef.current!.stop();
      }
    }
  },[isPlaying]);

  //Instantiate player only once
  //TODO: Update to useState or useRef like the other players
  const playerRef = useRef<Tone.Player | null>(null);
  if (playerRef.current === null) {
    playerRef.current = new Tone.Player(Track).toDestination();
    playerRef.current.loop = true;
  }

  const handleToggleClick = () => {
    if (isActive) {
      playerRef.current!.stop(); 
      setIsActive(false);
    } else {
      playerRef.current!.start(); 
      setIsActive(true);
    }
  }

  //Custom style for the 'on' state of the slider
  let activeStyleObj = {
    borderColor: colorSet.accentColor,
    backgroundColor: colorSet.accentColor
  }

  let inactiveStyleObj = {
    borderColor: colorSet.baseColor,
    backgroundColor: colorSet.baseColor
  }

  let bgStyleObj = {
    borderColor: colorSet.accentColor,
    backgroundColor: colorSet.accentColor,
    boxShadow: "inset 0px 301px 120px " + colorSet.foregroundColor
  }

  return (
    <div className="h-[84%] pt-4 pb-4 mr-4 ml-4 border-t-4 border-b-4" style={colorSet.borderStyleObj}>
      <div className="flex justify-center">
        <div className="relative w-64 h-96 mt-12 rounded-full border-4" style={bgStyleObj}>
          <button className={"absolute w-48 h-48 left-8 -m-1 transition-all border-4 rounded-full " + (isActive ? 'top-40' : 'top-8') } style={isActive ? activeStyleObj : inactiveStyleObj} onClick={handleToggleClick}></button>
        </div>
      </div>
    </div>
  );
}

export default SampleLoopModule;
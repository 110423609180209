import React, {useEffect, useState, useRef} from 'react'
import * as Tone from 'tone/build/esm'
import SynthStepSequencerModule from './module/synthmodules/StepSequencerModule'
import SampleLoopModule from './module/synthmodules/SampleLoopModule'
import SpeechModule from './module/synthmodules/SpeechModule'
import DrumModule from './module/synthmodules/DrumModule'
import VisualizationModuleP5 from './module/synthmodules/VisualizationModuleP5'
import VisualizationModuleThree from './module/synthmodules/VisualizationModuleThree'
import AudioEnergy from './assets/js/vendor/AudioEnergy'
import NotesModule from './module/utilitymodules/NotesModule'
import ModuleContainer from './module/ModuleContainer'
import Web3 from 'web3'
import {EffectTypes, InstrumentTypes, SynthTypes} from './assets/js/OofTypes'
import { proxy, snapshot, useSnapshot } from 'valtio'
import { store } from "./App"
import { Effect } from 'tone/build/esm/effect/Effect'

//TODO: Implement async preloader for samplers, these are just for easy debugging
import Kick from './assets/samples/Kick1.wav';
import Clap from './assets/samples/Clap1.wav';
import Tick from './assets/samples/Tick1.wav';
import Musical from './assets/samples/Musical1.wav';
import Hat from './assets/samples/HatClosed1.wav';
import Perc from './assets/samples/Perc1.wav';
import Shaker from './assets/samples/Shaker1.wav';
import Snare from './assets/samples/Snare1.wav';




//Define globally accessible objects that exist outside of React's state management
declare global {
  interface Window { $analyzer: any; }
}

window.$analyzer = new AudioEnergy(); //TODO: Make sure this is kosher, consider moving to a helper lib
const web3 = new Web3('ws://localhost:8546');

interface ManagerProps {}

const dummyData = {
  mainVolume: 100,
  oofs: [
      {
        id: web3.utils.randomHex(16),
        title: 'Polyphonic Mono synth',
        instrument: InstrumentTypes.StepSequencer,
        steps: 16,
        channels: 16,
        volume: 80,
        active: true,
        synths: [SynthTypes.Mono], //only one synth per step sequencer
        baseOctave: 3,
        effects: [EffectTypes.Compressor, EffectTypes.PingPongDelay], //EffectTypes.BitCrusher, EffectTypes.PingPongDelay
        visualizer: {},
        layout: '',
        colors: ['#FFF7E5'],
        enabled: true
      },
      {
        id: web3.utils.randomHex(16),
        title: 'FM Synth',
        instrument: InstrumentTypes.StepSequencer,
        steps: 32,
        channels: 16,
        volume: 80,
        active: true,
        synths: [SynthTypes.FM],
        baseOctave: 2,
        monophonic: true, //enables glissandro at the cost of polyphony
        effects: [EffectTypes.Reverb],
        visualizer: {},
        layout: '',
        colors: ['#E523E5'],
        enabled: true
      },
      {
        id: web3.utils.randomHex(16),
        title: 'Harsh Mono',
        instrument: InstrumentTypes.StepSequencer,
        steps: 16,
        channels: 16,
        volume: 80,
        active: true,
        synths: [SynthTypes.Mono],
        baseOctave: 3,
        monophonic: true,
        effects: [EffectTypes.BitCrusher, EffectTypes.Reverb],
        visualizer: {},
        layout: '',
        colors: ['#b45309'],
        enabled: false
      },
      {
        id: web3.utils.randomHex(16),
        title: 'Noise loop',
        instrument: InstrumentTypes.SampleLoop,
        volume: 80,
        active: true,
        synths: [],
        effects: [],
        visualizer: {},
        layout: '',
        colors: ['#E5EAFF'],
        enabled: true
      },
      {
        id: web3.utils.randomHex(16),
        title: 'Basic drums (no effects)',
        instrument: InstrumentTypes.Drums,
        steps: 16,
        channels: 16,
        volume: 80,
        active: true,
        synths: [],
        effects: [EffectTypes.Reverb],
        visualizer: {},
        layout: '',
        colors: ['#7e22ce'],
        enabled: true
      },
      {
        id: web3.utils.randomHex(16),
        title: '70s Drums',
        instrument: InstrumentTypes.StepSequencer,
        steps: 16,
        channels: 8,
        volume: 80,
        active: true,
        synths: [SynthTypes.Player],
        baseOctave: 0,
        monophonic: false,
        effects: [EffectTypes.Reverb],
        visualizer: {},
        options: {audioBuffers: [Kick, Clap, Hat, Shaker, Snare, Perc, Tick, Musical]},
        layout: '',
        colors: ['#64860c'],
        enabled: true
      },
  ]
}



/*

<Module baseColor="#b45309" title="STP">
  <SynthStepSequencerModule baseColor="#b45309" setPosition={setPosition} position={position} channels={16} steps={16} />
</Module>

*/


const Manager = (props:ManagerProps) => {
  const snap = useSnapshot(store);

  //Init Tone transport once
  useEffect(() => {
    Tone.Transport.scheduleRepeat((time) => {
      if (store.globalPosition < 0) {
        store.globalPosition = 0;
      } else {
        store.globalPosition = (store.globalPosition+1) % 32; //TODO: Change max length to be dynamic depending on the longest step seq module
      }
    }, "16n", 0);
  }, []);


  const getModule = (oofData: any) => {
    switch(oofData.instrument) {

      case InstrumentTypes.StepSequencer:   return <SynthStepSequencerModule baseColor={oofData.colors[0]} channels={oofData.channels} steps={oofData.steps} synth={oofData.synths[0]} baseOctave={oofData.baseOctave} effects={oofData.effects} monophonic={oofData.monophonic ? true : false} options={oofData.options} />
      case InstrumentTypes.SampleLoop:   return <SampleLoopModule baseColor={oofData.colors[0]} />
      case InstrumentTypes.Drums: return <DrumModule baseColor={oofData.colors[0]} channels={3} steps={oofData.steps} />

      default: return <span>Unknown Oof type</span>
    }
  }

  return (
    <div className="flex flex-wrap">
      {
        dummyData.oofs.map(oof => {
          if (oof.enabled) {
            return (
              <ModuleContainer key={oof.id} baseColor={oof.colors[0]} title={oof.title} code={ String(oof.id).substring(String(oof.id).length - 3).toUpperCase() }>
                { getModule(oof) }
              </ModuleContainer>
            )
          }
        })
      }

      <ModuleContainer key={-1} code="TXT" baseColor="#ffffff" title="Notes">
        <NotesModule baseColor="#ffffff" />
      </ModuleContainer>
      
      {/* <Module baseColor="#FFF7E5" title="3JS">
        <VisualizationModuleThree baseColor="#FFF7E5" />
      </Module>

      <Module baseColor="#FBE5FF" title="P5J">
        <VisualizationModuleP5 baseColor="#FBE5FF" />
      </Module>

      <Module baseColor="#D4FF00" title="STP">
        <SpeechModule baseColor="#D4FF00" setPosition={setPosition} position={snap.position} channels={2} steps={16} />
      </Module> */}
    </div>
  );
}

export default Manager;
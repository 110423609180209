import * as Tone from 'tone/build/esm';
import { Note } from '@tonaljs/tonal';
import { Player } from 'tone/build/esm';
import { Time } from 'tone/build/esm/core/type/Units';

interface SynthOptions {
  envelope?: Object | Tone.EnvelopeOptions,
  oscillator?: Object | Tone.OmniOscillatorOptions,
  portamento?: number, //seconds
  volume?: number //decibels
}

export enum OscillatorTypes {
  Sine = "sine",
  Square = "square",
  Triangle = "triangle",
  Sawtooth = "sawtooth",
}

export default class SoundModule {
  note: string = 'A';
  noteDuration: string = '32n'; //32n seems a little nicer as a default value than 16n, but might reassess
  instrument: Tone.MonoSynth | Tone.FMSynth | Tone.DuoSynth | Tone.Synth | Tone.Sampler | Tone.Player | Tone.Sampler | Tone.PolySynth;
  // constructor(){
  //   this.instrument = new Tone.MonoSynth({
  //     portamento: 0.2,
  //     volume: -12
  //   });
  // }

  getNote(){
    return this.note;
  }

  testTone(){
    if (this.instrument instanceof Player) {
      this.instrument.start();
    } else {
      this.instrument.triggerAttackRelease(this.getNote(), this.noteDuration);
    }
    
  }

  getOscillatorTypes() {
    return Object.values(OscillatorTypes);
  }

  setOscillatorTypeIndex(oscTypeIndex: number){
    const oscTypeString = Object.values(OscillatorTypes)[oscTypeIndex];
    this.setOscillatorType("fat" + oscTypeString);
  }
  
  setOscillatorType(oscTypeString: string){
    this.setOptions({
      oscillator: {
        type: oscTypeString,
      }
    });
  }

  setLinearVolume(val: number){
    //const volDB = Math.log10(val) * 20; //convert linear 0-1 value to log volume (-infinity to 0)
    const volDB = Tone.gainToDb(val);
    this.setVolume(volDB);
  }

  setVolume(val: number){
    this.setOptions({
      volume: val
    })
  }

  setPortamento(val: number){
    this.setOptions({
      portamento: val
    })
  }
  
  setAmplitudeEnvelope = (params: {attack?: number, decay?: number, sustain?: number, release?: number}) => {
    const i = this.instrument;

    this.setOptions({
      envelope: params
    });
  }

  setOptions = (options: SynthOptions) => {
    const i = this.instrument;
    if (i instanceof Tone.MonoSynth || i instanceof Tone.FMSynth || i instanceof Tone.Synth || i instanceof Tone.PolySynth ||  i instanceof Tone.Player) {
      i.set(options);
    } else {
      console.log('Unsupported synth type');
    }
  }


  play(){
    this.playAtTime(Tone.now());
  }

  playNote(note: string){
    if (this.instrument instanceof Player) {
      this.instrument.start();
    } else {
      this.instrument.triggerAttackRelease(note, this.noteDuration, Tone.now());
    }
  }

  playAtTime(time: Time){
    if (this.instrument instanceof Player) {
      this.instrument.start(time);
    } else {
      try {
        this.instrument.triggerAttackRelease(this.getNote(), this.noteDuration, time);
      } catch(e) {
        //TODO: Figure out the root cause of this event. It seems that sounds are scheduled in the past sometimes.
        console.log("Timing off, skipping note.");
      }
    }
  }
}
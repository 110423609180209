import React, {useState} from 'react';
import { nanoid } from 'nanoid';


interface ModuleProps {
  val: number,
  min?: number,
  max?: number,
  padWithZeroes?: number,
  onChange: Function
}

const Scrubber = (props:ModuleProps) => {
  const [isDragging, setIsDragging] = useState(false);
  const sensitivity = 5; //lower = more sensitive, 1 = 1:1 pixel movement
  let initialValueOnDrag = 0;
  let initialDragPosition = 0;

  const mouseDownHandler = (e: React.MouseEvent) => {
    setIsDragging(true);
    initialDragPosition = e.pageY;
    initialValueOnDrag = props.val;
    e.preventDefault();

    let moveListener = (e: MouseEvent) => {
      let delta = Math.round((initialDragPosition - e.pageY)/sensitivity);
      let newValue = initialValueOnDrag + delta;
      
      if (props.max !== undefined) newValue = Math.min(newValue, props.max);
      if (props.min !== undefined) newValue = Math.max(newValue, props.min);

      props.onChange(newValue);
    }

    document.addEventListener('mousemove', moveListener);

    document.addEventListener('mouseup', (e) => {
      setIsDragging(false);
      document.removeEventListener('mousemove', moveListener);
    });
  }

  const getRenderedValue = () => {
    if (props.padWithZeroes) {
      return String(props.val).padStart(props.padWithZeroes, '0');
    } else {
      return props.val;
    }
  }

  return (
    <div className="flex items-center w-24 group cursor-ns-resize" onMouseDown={ mouseDownHandler }>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-6 h-6 group-hover:stroke-highlight + ${isDragging ? "stroke-highlight" : "stroke-current"}`}>
          <path strokeLinecap="round" strokeLinejoin="round" d="M3 7.5L7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5" />
        </svg>
      <div className={`grow font-mono group-hover:text-highlight + ${isDragging ? "text-highlight" : "text-current"}`}>{ getRenderedValue() }</div>
    </div>
  )
}

export default Scrubber;
import React, {useEffect, useState, useRef} from 'react';
import * as Tone from 'tone/build/esm';
import SoundModule from '../../assets/js/SoundModuleClass';
import { Step, StepSequencer } from '../../assets/js/StepSequencerClass';
import Kick from '../../assets/samples/Kick1.wav';
import Clap from '../../assets/samples/Clap1.wav';
import Tick from '../../assets/samples/Tick1.wav';
import StepSequencerComponent from '../components/StepSequencerComponent'
import ColorSystem from '../../assets/js/ColorSystem';
import TinyButton from '../components/TinyButton';
import { store } from "../../App"
import { useSnapshot } from 'valtio';


interface ModuleProps {
  channels: number,
  steps: number,
  baseColor: string
}

//TODO: Convert this to a live 4x4 drum pad module
const DrumModule = (props:ModuleProps) => {
  const colorSet = new ColorSystem(props.baseColor).getColors();
  const { globalPosition } = useSnapshot(store);
  
  const [matrix, setMatrix] = useState(
    Array.from({length: props.steps}, (): Step[]=> Array.from({length: props.channels}, ():Step => { return {on: false} }))
  );

  //Use a function to make sure state is only initialized once
  const [stepSequencer, setStepSequencer] = useState(() => {
    return new StepSequencer(props.channels, props.steps );
  });

  //Update matrix
  useEffect(()=>{
    stepSequencer.setMatrix(matrix);
  },[matrix]);

  //Pass along globalPosition updates to the stepsequencer
  useEffect(()=>{
    stepSequencer.position = globalPosition;
  },[globalPosition]);

  useEffect(() => {
    //Create array of SoundModules (can be mixed)
    let sounds = [];
    let audioBuffers = [Kick, Clap, Tick];
    for (let i = 0; i<stepSequencer.getNumberOfChannels(); i++){
      let sm = new SoundModule();
      //sm.instrument = new Tone.Sampler({ Kick }).toDestination();

      sm.instrument = new Tone.Player(
        audioBuffers[i]
      ).toDestination();
      sounds.push(sm);
    }

    stepSequencer.setSoundModules(sounds);
    stepSequencer.setMatrix(matrix);
  }, []);

  const handleMuteClick = () => {stepSequencer.mute();}
  const handleClearClick = () => {
    let clearMatrix = Array.from({length: props.steps}, (): Step[]=> Array.from({length: props.channels}, ():Step => { return {on: false} }));
    setMatrix(clearMatrix);
  }

  return (
    <div className="h-[84%] pt-4 pb-4 mr-4 ml-4 border-t-4 border-b-4" style={colorSet.borderStyleObj}>
      <div className="absolute top-8 left-1/2 z-10">
        <TinyButton label="M" tip="Mute/unmute" onClick={handleMuteClick}></TinyButton>
        <TinyButton label="C" tip="Clear" onClick={handleClearClick}></TinyButton>
      </div>
      <StepSequencerComponent baseColor={props.baseColor} channels={props.channels} steps={props.steps} matrix={matrix} setMatrix={setMatrix}></StepSequencerComponent>
    </div>
  );
}

export default DrumModule;
import React, { MouseEventHandler, useMemo } from 'react';
import StepSequencerButton from "./StepSequencerButton";
import { Step } from '../../assets/js/StepSequencerClass';
import { store } from "../../App"
import { useSnapshot } from 'valtio';

interface ModuleProps {
  colIndex: number,
  col: Step[],
  steps: number,
  baseColor: string,
  onDown: Function,
  onMove: Function
}

const StepSequencerColumn = (props:ModuleProps) => {
  const { globalPosition } = useSnapshot(store);
  
  const memoizedCol = useMemo(
    ()=>props.col, 
    [(prevProps:Step[][], nextProps:Step[][]) => { 
      return JSON.stringify(prevProps) === JSON.stringify(nextProps)
    }]
  );

  const onDown = (colIndex: number, rowIndex: number) => {
    props.onDown(colIndex, rowIndex);
  }

  const onMove = (colIndex: number, rowIndex: number) => {
    props.onMove(colIndex, rowIndex);
  }

  return (
    <div className="flex flex-col-reverse flex-1">
      {memoizedCol.map((row, rowIndex) => {
        let dynClass = '';
        let btnState = 'off';

        if (row.on) {
          if ((globalPosition%props.steps) === props.colIndex){
            btnState = 'highlight-on';
          } else {
            btnState = 'on';
          }
        } else {
          if ((globalPosition%props.steps) === props.colIndex) { btnState = 'highlight-off'; }
        }


        //Draw helper borders and remove duplicate (neighboring) inner borders
        if (props.colIndex > 0) {
          //Darken every 4 bars
          if (props.colIndex % 4 === 0) {
            dynClass += ' border-l-1';
          } else {
            dynClass += ' border-l-0';
          }
        }

        if (rowIndex > 0) {
          //Darken every half octave
          if (rowIndex % 4 === 0) {
            dynClass += ' border-b-1';
          } else {
            dynClass += ' border-b-0';
          }
        }
        
        return <StepSequencerButton key={'btn-' + rowIndex + '-' + props.colIndex} btnState={btnState} baseColor={props.baseColor} dynamicClass={dynClass} onMove={() => onMove(props.colIndex, rowIndex)} onDown={() => onDown(props.colIndex, rowIndex)} /> //TODO: Add params
      })}
    </div>
  );
}

StepSequencerColumn.whyDidYouRender = true;
export default React.memo(StepSequencerColumn);
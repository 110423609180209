import React, { FormEvent, useEffect, useState } from 'react';
import ColorSystem from '../../assets/js/ColorSystem';

interface ModuleProps {
  label: string,
  tip?: string | number,
  baseColor: string,
  value: number,
  min: number,
  max: number,
  step: number,
  enableFill?: boolean,
  onChange: Function
}

const TinyButton = (props: ModuleProps) => {
  const colorSet = new ColorSystem(props.baseColor).getColors();
  const [val, setVal] = useState(props.value);

  const handleChange = (evt: FormEvent<HTMLInputElement>) => {
    setVal(Number(evt.currentTarget.value));
    props.onChange(evt.currentTarget.value);
  }

  return (
    <>
      <div className="text-xs font-bold uppercase tracking-tighter">{props.label}</div>
      <div className="tooltip" data-tip={props.tip}>
        <input 
          style={{
            "--value": val, 
            "--min": props.min, 
            "--max": props.max, 
            "--fgColor": colorSet.foregroundColor, 
            "--handleActiveColor": colorSet.accentColor + 'ff',
            "--handleHoverColor": colorSet.accentColor + '99'
          } as React.CSSProperties} 
          className={"styled-slider" + (props.enableFill ? " slider-progress" : "")} 
          type="range" value={val} 
          step={props.step} 
          min={props.min} 
          max={props.max} 
          onChange={(evt) => ( handleChange(evt) ) } 
        />
        {/* Daisy UI version */}
        {/* <input 
          style={{"--fgColor": props.fgColor} as React.CSSProperties} 
          className="range range-xs"
          type="range" value={val} 
          step={props.step} 
          min={props.min} 
          max={props.max} 
          onChange={(evt) => ( handleChange(evt) ) } 
        /> */}
      </div>
    </>
  )
}

export default TinyButton;
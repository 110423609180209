import React from 'react';
import Manager from './Manager';
import Header from './module/Header';
import * as Tone from 'tone/build/esm';
import { proxy, useSnapshot } from 'valtio';

import './App.css';
import { sortedIndex } from 'lodash';

export const store = proxy({
  audioInitialized: false,
  globalPosition: -1, //-1 means not started
  bpm: 75,
  isPlaying: false
});

export const GlobalControls = {
  play: () => {
    Tone.Transport.start("+0.1");
    store.isPlaying = true;
  },
  pause: () => {
    Tone.Transport.pause();
    store.isPlaying = false;
  },
  stop: () => {
    Tone.Transport.stop();
    store.globalPosition = -1;
    store.isPlaying = false;
  },
  setBPM: (bpm: number) => {
    Tone.Transport.bpm.setValueAtTime(bpm, Tone.Transport.now());
    console.log("BPM set to " + bpm);
    store.bpm = bpm;
  },
  increaseBPM: () => { 
    ++store.bpm;
    Tone.Transport.bpm.value = store.bpm;
  },
  decreaseBPM: () => { 
    --store.bpm;
    Tone.Transport.bpm.value = store.bpm;
  },
  setGlobalPosition: (val: number) => {
    store.globalPosition = val;
  },
}


function App() {
  document.querySelector('body')?.addEventListener('click', async () => {
    if (!store.audioInitialized){
      await Tone.start();
      Tone.setContext( new Tone.Context( { latencyHint : "playback", context: new AudioContext( {sampleRate: 32000} ) } ) );
      Tone.Transport.bpm.value = store.bpm
      console.log('Audio is ready.');
      store.audioInitialized = true;
    }
  })

  return (
    <div className="App">
      <Header address="00x0"></Header>
      <Manager></Manager>
    </div>
  );
}

export default App;

import React from 'react';

interface ModuleProps {
  label: string,
  tip?: string
  onClick: Function
}

const TinyButton = (props:ModuleProps) => {

  const handleClick = () => {
    props.onClick();
  }

  return (
    <div className="tooltip" data-tip={props.tip}>
      <button className="btn btn-xs btn-outline w-6 aspect-square rounded-full uppercase border-0 tracking-tight text-sm hover:bg-black hover:text-gray-400" onClick={handleClick}>{props.label}</button>
    </div>
  )
}

export default TinyButton;
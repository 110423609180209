import React, { useRef } from 'react';
import * as Tone from 'tone/build/esm'
import { store, GlobalControls } from "../App"
import { proxy, snapshot, useSnapshot } from 'valtio'
import { useState } from 'react'
import Scrubber from './components/ScrubberComponent'
import _ from 'lodash';


interface HeaderProps {
  address: string
}

const Header = (props:HeaderProps) => {
  const handlePlayClick = () => { GlobalControls.play() }
  const handlePauseClick = () => { GlobalControls.pause() }
  const handleStopClick = () => { GlobalControls.stop() }
  const handleBPMIncreaseClick = () => { GlobalControls.increaseBPM() }
  const handleBPMDecreaseClick = () => { GlobalControls.decreaseBPM() }

  function PlayPauseButton(){
    const {isPlaying} = useSnapshot(store);
    if (isPlaying) {
      return (
        <button className="rounded-full border-2 w-10 aspect-square bg-black" onClick={ handlePauseClick }>
          <svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 24 24" strokeWidth={3} stroke="white" className="w-6 h-6 mx-auto">
            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 5.25v13.5m-7.5-13.5v13.5" />
          </svg>
        </button>
      )
    } else {
      return (
        <button className="rounded-full border-2 w-10 aspect-square bg-black" onClick={ handlePlayClick }>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" strokeWidth={0} stroke="white" className="w-6 h-6 ml-2">
            <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z" />
          </svg>
        </button>
      )
    }
  }

  function StopButton(){
    return (
      <button className="rounded-full border-2 w-10 aspect-square bg-black" onClick={ handleStopClick }>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" className="w-6 h-6 mx-auto">
          <path fillRule="evenodd" d="M4.5 7.5a3 3 0 013-3h9a3 3 0 013 3v9a3 3 0 01-3 3h-9a3 3 0 01-3-3v-9z" clipRule="evenodd" />
        </svg>
      </button>
    )
  }

  function PositionIndicator() {
    const {globalPosition, isPlaying} = useSnapshot(store);
    return (
      <Scrubber min={0} padWithZeroes={0} val={globalPosition} onChange={(val: number) => { if (!isPlaying) GlobalControls.setGlobalPosition(val) }}/>
    )
  }

  function BPMController(){
    const {bpm} = useSnapshot(store)
    const [targetBPM, setTargetBPM] = useState(bpm);
    const setBPMValue = (val: number) => GlobalControls.setBPM(val);
    const setBPMDebounced = useRef(_.debounce(setBPMValue, 1000));

    const handleBPMChange = (val: number) => {
      setTargetBPM(val);
      setBPMDebounced.current(val);
    }
    
    return (
      <>
        <Scrubber min={0} max={1024} padWithZeroes={0} val={targetBPM} onChange={(val: number) => { handleBPMChange(val) }}/>
      </>
    )
  }

  return (
    <div className="relative h-[13%] z-10">
      <div className="flex flex-row text-5xl tracking-tighter p-4 h-20">
        <div className="relative basis-3/4 border-t-4 border-primary">
          <div className="relative top-1 ml-1 mr-2 max-h-11"></div>
        </div>
        <div className="relative basis-1/4 border-t-4 border-l-4 border-primary">
          <div className="absolute top-1 ml-1 text-sm uppercase font-bold tracking-tight">POS</div>
          <div className="absolute top-1 ml-1 mr-2 right-0 text-right max-h-11"><PositionIndicator /></div>
        </div>
        <div className="relative basis-1/4 border-t-4 border-l-4 border-primary">
          <div className="absolute top-1 ml-1 text-sm uppercase font-bold tracking-tight">CTL</div>
          <div className="absolute top-0 mr-2 right-0 text-right max-h-11">
            <StopButton />
            <PlayPauseButton />
          </div>
        </div>
        <div className="relative basis-1/4 border-t-4 border-l-4 border-primary">
          <div className="absolute top-1 ml-1 text-sm uppercase font-bold tracking-tight">BPM</div>
          <div className="absolute top-1 ml-1 mr-2 right-0 text-right max-h-11 flex">
            <BPMController />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
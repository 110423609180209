import ModuleHeader from "./ModuleHeader";
import ColorSystem from "../assets/js/ColorSystem";
import { store } from "../App";
import { proxy, snapshot, useSnapshot } from "valtio";
import Draggable from "react-draggable";
import { motion } from "framer-motion";


interface ModuleProps {
  title: string,
  code: string,
  baseColor: string,
  children?: JSX.Element|JSX.Element[]
}


const ModuleContainer = (props:ModuleProps) => {
  const snap = useSnapshot(store);
  const colors = new ColorSystem(props.baseColor).getColors();

  const styleObj = {
    backgroundColor: colors.backgroundColor,
    borderColor: colors.foregroundColor
  }

  return (
    <Draggable handle=".titlebar" defaultClassNameDragging="dragging">
      <div className="flex flex-wrap group z-10">
        <div className="cursor-default titlebar w-[600px] bg-gray-300 h-7 left-4 top-4 transition-[top] duration-150 absolute z-0 border-black border-4 group-hover:-top-2 dragging:-top-2 dragging:bg-gray-400">
          <div className="top-1 ml-4 flex flex-row">
            <div className="grow text-sm uppercase font-bold tracking-tight">{props.title}</div>
            <div className="tooltip text-xs" data-tip={"Foreground: " + colors.foregroundColor}>
              <div className="w-2 h-3 mt-1 justify-self-end" style={{"backgroundColor": colors.foregroundColor} as React.CSSProperties}></div>
            </div>

            <div className="tooltip text-xs" data-tip={"Accent: " + colors.accentColor}>
              <div className="w-2 h-3 mt-1 justify-self-end" style={{"backgroundColor": colors.accentColor} as React.CSSProperties}></div>
            </div>

            <div className="tooltip text-xs" data-tip={"Background: " + colors.backgroundColor}>
              <div className="w-2 h-3 mt-1 mr-1 justify-self-end" style={{"backgroundColor": colors.backgroundColor} as React.CSSProperties}></div>
            </div>
          </div>
        </div>
        <div className="relative container aspect-square w-[600px] m-4 border-4" style={styleObj}>
          <ModuleHeader baseColor={props.baseColor}>{props.code}</ModuleHeader>
          {props.children}
        </div>
      </div>
    </Draggable>
  );
}

export default ModuleContainer;
import React, { MouseEventHandler } from 'react';
import { motion } from "framer-motion";
import ColorSystem from '../../assets/js/ColorSystem';

interface ModuleProps {
  dynamicClass: string,
  baseColor: string,
  onDown: MouseEventHandler<HTMLButtonElement>,
  onMove: MouseEventHandler<HTMLButtonElement>,
  btnState: string
}

const StepSequencerButton = (props:ModuleProps) => {
  const colorSet = new ColorSystem(props.baseColor).getColors();

  // General animation states
  function animateState() {
    switch (props.btnState){
      case 'on':
        return { backgroundColor: colorSet.foregroundColor + 'ff' }; //note on
      case 'highlight-on':
        return { backgroundColor: colorSet.accentColor + 'ff' }; //note on and highlighted (ie, at active step of the sequencer)
      case 'highlight-off':
        return { backgroundColor: colorSet.accentColor + '99' };
      default:
        return { backgroundColor: colorSet.foregroundColor + '00' };
    }
  }

  // Hover states
  function animateHoverState() {
    switch (props.btnState){
      case 'on':
        return { backgroundColor: colorSet.foregroundColor + 'aa'}; //note on, but not highlighted
      case 'highlight-on':
        return { backgroundColor: colorSet.accentColor + 'aa'}; //note on and highlighted
      case 'highlight-off':
        return { backgroundColor: colorSet.accentColor + '66'};
      default:
        return { backgroundColor: colorSet.foregroundColor + '44'};
    }
  }

  return (
    <motion.button 
      animate={animateState()}
      style={{"borderColor": colorSet.foregroundColor} as React.CSSProperties} 
      transition={{ ease: 'easeOut', duration: 0.0 }}
      whileHover={animateHoverState()}
      className={"flex-1 rounded-none m-0 border-1 " + props.dynamicClass}
      onMouseMove={props.onMove}
      onMouseDown={props.onDown}
    ></motion.button>
  );
}

export default React.memo(StepSequencerButton);
import React, {useEffect, useState, useRef} from 'react';
import * as Tone from 'tone/build/esm';
import { Note, Scale, Chord, ChordType } from '@tonaljs/tonal';
import SoundModule, { OscillatorTypes } from '../../assets/js/SoundModuleClass';
import {Step, StepSequencer} from '../../assets/js/StepSequencerClass';
import StepSequencerComponent from '../components/StepSequencerComponent'
import ColorSystem from '../../assets/js/ColorSystem';
import { SynthTypes, EffectTypes } from '../../assets/js/OofTypes';
import TinyButton from '../components/TinyButton';
import CustomRangeSlider from '../components/CustomRangeSlider';
import { store } from "../../App"
import { useSnapshot } from 'valtio';

interface ModuleProps {
  channels: number,
  steps: number,
  baseColor: string,
  synth: SynthTypes,
  effects?: Array<EffectTypes>,
  baseOctave: number,
  monophonic: boolean,
  options: any
}

console.warn('Rewrite getSynth() to be a little more efficient and DRY');
const SynthStepSequencerModule = (props:ModuleProps) => {
  const { globalPosition } = useSnapshot(store);

  // TODO: Move out into a separate Effect factory class
  interface EffectObject {
    filter: any,
    exposesWetness: boolean,
    exposedParameters: Array<any> | undefined
  }

  const getEffect = (type: number): EffectObject => {
    switch(type){
      case EffectTypes.Compressor:
        return {
          filter: new Tone.Compressor(-30, 3),
          exposesWetness: false,
          exposedParameters: undefined
        } as EffectObject;
      case EffectTypes.Reverb:
        return {
          filter: new Tone.Reverb({decay: 5, wet: 0.5, preDelay: 0.2}),
          exposesWetness: true,
          exposedParameters: undefined
        } as EffectObject;
      case EffectTypes.PingPongDelay:
        return {
          filter: new Tone.PingPongDelay("16n", 0.2),
          exposesWetness: true,
          exposedParameters: undefined
        } as EffectObject;
      case EffectTypes.BitCrusher:
        return {
          filter: new Tone.BitCrusher(4),
          exposesWetness: true,
          exposedParameters: undefined
        } as EffectObject;
      case EffectTypes.Chorus:
        return {
          filter: new Tone.Chorus(4, 25, 0.5),
          exposesWetness: true,
          exposedParameters: undefined
        } as EffectObject;
      default:
        return {
          filter: new Tone.Volume(0), //Return a dumb volume node if nothing usable comes through. //TODO: I could be smarter here.
          exposesWetness: false,
          exposedParameters: undefined
        } as EffectObject;
    }
  }

  // Init color system
  const colorSet = new ColorSystem(props.baseColor).getColors();

  // Returns an empty matrix
  const getClearMatrix = (): Step[][] => {
    return Array.from({length: props.steps}, (): Step[]=> Array.from({length: props.channels}, ():Step => { return {on: false} }));
  }

  //const matrix = useRef(getClearMatrix());
  const [matrix, setMatrix] = useState(getClearMatrix());
  const [baseOctave, setBaseOctave] = useState(props.baseOctave);
  const [amplitudeEnvelope, setAmplitudeEnvelope] = useState({attack: 0.05, decay: 0.5, sustain: 0.5, release: 0.8});
  const [effects, setEffects] = useState<Array<EffectObject> | undefined>();
  const [portamento, setPortamento] = useState(0);
  const [wetness, setWetness] = useState(1.0);
  const [enableWetness, setEnableWetness] = useState(false);
  const [oscillatorType, setOscillatorType] = useState(0);
  const [volume, setVolume] = useState(0.8); //linear volume 0-1, log conversion happens in the sound module class

  //Use a function to make sure state is only initialized once
  const [stepSequencer, setStepSequencer] = useState(() => {
    return new StepSequencer(props.channels, props.steps );
  });

  // const setMatrix = (newMatrix: Step[][]) => {
  //   matrix.current = newMatrix;
  //   stepSequencer.setMatrix(matrix.current);
  // }

  useEffect(()=>{
    stepSequencer.setMatrix(matrix);
  }, [matrix]);


  //Get effects
  useEffect(()=>{
      const effects = props.effects?.map((effect) => { return getEffect(effect) });
      setEffects(effects);
  }, []);

  //Pass along globalPosition updates to the stepsequencer
  useEffect(()=>{
    stepSequencer.position = globalPosition;
  },[globalPosition]);

  // Handle setup
  useEffect(() => {
    // Create array of SoundModules (can be mixed)
    let sounds = [];

    if (props.synth == SynthTypes.Player){
      for (let i=0; i<props.options.audioBuffers.length; i++){
        let sm = new SoundModule();
        sm.instrument = new Tone.Player({url: props.options.audioBuffers[i], volume: Tone.gainToDb(volume)}); //TODO: Add support for sample names
        sounds.push(sm);
      }
    } else {
      let synth = getSynth(props.synth, props.monophonic);
      for (let i=0; i<stepSequencer.getNumberOfChannels(); i++){
        let sm = new SoundModule();
        sm.instrument = synth;
        sounds.push(sm);
      }
    }

    stepSequencer.setSoundModules(sounds);
    stepSequencer.setAmplitudeEnvelope(amplitudeEnvelope);
    stepSequencer.setMatrix(matrix); //set reference to matrix, remember that the stepsequencer class doesn't deep clone it
  }, []);

  // Hook up effects
  useEffect(() => {
    const effectChain = [];
    if (Tone.isArray(effects)) {
      for (let i=0; i<effects.length; i++) {
        const effect = effects[i] as EffectObject;
        if (Tone.isDefined(effect)){
          effectChain.push(effect.filter);
          if (effect.exposesWetness) { setEnableWetness(true); } //if any of the effects expose wetness, enable the slider
        }
      }
    }

    for (let sm of stepSequencer.sounds){
      sm.instrument.disconnect();
      sm.instrument.chain(...effectChain, Tone.Destination);
    }
  }, [effects]);


  useEffect(() => {
    stepSequencer.setPortamento(portamento);
  }, [portamento]);

  useEffect(() => {
    stepSequencer.setOscillatorType(oscillatorType);
  }, [oscillatorType]);

  useEffect(() => {
    stepSequencer.setLinearVolume(volume);
  }, [volume]);

  useEffect(() => {
    if (Tone.isDefined(effects)){
      for (let effect of effects){
        effect.filter.set({wet: wetness});
      }
    }
  }, [wetness]);


  // Handle octave change
  useEffect(() => {
    const expandedScales = getScale("C", "chromatic", baseOctave, props.channels);

    // Assign new base notes
    for (let [i, sm] of stepSequencer.sounds.entries()) {
      sm.note = expandedScales.flat()[i];
    }

  }, [baseOctave]);

  // Handle ADSR change
  useEffect(() => {
    stepSequencer.setAmplitudeEnvelope(amplitudeEnvelope);
  }, [amplitudeEnvelope]);  


  // Create array of scale notes that cover the entire span of the synth
  // Refer to Tonal.js ScaleTypes documentation
  // TODO: Break out into separate file when the need arises
  const getScale = (key: string, type: string, octave: number, minLength: number) => {
    const scale = Scale.get(key + octave + " " + type);
    const expandedScales: Array<Array<string>> = [scale.notes];

    // Assemble scale array large enough to cover all channels
    for (let i=1; i<Math.ceil(minLength/scale.notes.length); i++){
      expandedScales.push(
        expandedScales[i-1].map(Note.transposeBy("P8")) //transpose an octave
      );
    }

    return expandedScales;
  }

  // Create synth
  // TODO: Move out into a separate Synth Factory class
  // TODO: PolySynth seems to run into a max polyphony issue when using a full grid. Check if this is fixable or a limitation of the webaudio api.
  // TODO: Implement promises to make sure effects are ready before hooking them up
  const getSynth = (type: number, monophonic: boolean = false) => {
    const defaultParams = {
      volume: Tone.gainToDb(volume)
    }

    //TODO: This is kinda messy, look into whether it's feasible to wrap all synths in a polysynth and just lower maxpolyphony
    if (monophonic){
      switch(type){
        case SynthTypes.Mono:
          return new Tone.MonoSynth( { portamento: portamento, ...defaultParams });
        case SynthTypes.FM:
          return new Tone.FMSynth( { portamento: portamento, ...defaultParams });
        default:
          return new Tone.Synth( { portamento: portamento, ...defaultParams });
      }
    } else {
      switch(type){
        case SynthTypes.Mono:
          return new Tone.PolySynth({ voice: Tone.MonoSynth, maxPolyphony: 32, ...defaultParams });
        case SynthTypes.FM:
          //TODO: Improve logic to avoid making it possible to create polysynths with polyphonic synths
          console.warn("Polysynth is using a non-monophonic synth; this tends to lead to dropped notes");
          return new Tone.PolySynth({ voice: Tone.FMSynth, maxPolyphony: 32, ...defaultParams });
        default:
          console.warn("Polysynth is using a non-monophonic synth; this tends to lead to dropped notes");
          return new Tone.PolySynth({ voice: Tone.Synth, maxPolyphony: 32, ...defaultParams });
      }
    }

  }

  
  
 

  const handleMuteClick = () => {stepSequencer.mute();}
  const handleClearClick = () => {
    let clearMatrix = getClearMatrix();
    setMatrix(clearMatrix);
  }

  const handleShiftRightClick = () => {
    let newMatrix = matrix.map(inner => inner.slice());

    let o:Step[] = newMatrix.pop() as Step[];
    newMatrix.unshift(o);

    setMatrix(newMatrix);
  }

  const handleShiftUpClick = () => {
    let newMatrix = matrix.map(inner => inner.slice());

    for (let arr of newMatrix){
      arr.unshift(arr.pop() as Step);
    }

    setMatrix(newMatrix);
  }

  const handleSynthParameterChange = (params: {attack?: number, decay?: number, sustain?: number, release?: number}) => {
    const mergedADSR = {
      ...amplitudeEnvelope,
      ...params
    }
    
    setAmplitudeEnvelope(mergedADSR);
  }

  const fillWithArpeggio = () => {
    //TODO: Support different arp walk modes, current does upDown
    const allChords = ChordType.all();
    const chord = allChords[Math.floor(Math.random() * allChords.length)];
    const chordNotes = chord.chroma;

    console.log(chord.aliases[0]);

    let randMatrix = getClearMatrix();

    let chordIntervalIndices = [];
    for (let i=0; i<chordNotes.length; i++){
      if (chordNotes[i] == "1"){
        chordIntervalIndices.push(i);
      }
    }

    let index = 0;
    let dir = 1;
    for (let col of randMatrix){
      let intervalIndex = chordIntervalIndices[index];
      if (intervalIndex >= col.length) {
        intervalIndex = intervalIndex%col.length;
        //TODO: Find the best way to work around this limitation
        console.log("Arpeggio is longer than the note span of the synth. Wrapping around.");
      }
      col[intervalIndex].on = true;

      index += dir;
      if (index >= chordIntervalIndices.length-1){
        dir = -1;
        index = chordIntervalIndices.length-1;
      } else if (index <= 0) {
        dir = 1;
        index = 0;
      }
    }

    setMatrix(randMatrix);
  }

  const fillRandomly = () => {
    const fillRate = 0.1 + (Math.random() * 0.9); //0..1 
    let randMatrix = getClearMatrix();
    for (let col of randMatrix){
      const index = Math.round(gaussianRandom(0, col.length-1) * (1/fillRate) ); //a little easier to read
      if (index < col.length){
        col[index].on = true;
      }
    }

    setMatrix(randMatrix);
  }

  // Algo from here: https://stackoverflow.com/a/39187274/2731154
  const gaussianRand = () => {
    let rand = 0;
    const factor = 6; //default 6, lower number loosens up distribution
  
    for (var i = 0; i < factor; i += 1) {
      rand += Math.random();
    }
  
    return rand / factor;
  }

  const gaussianRandom = (start: number, end: number) => {
    return Math.floor(start + gaussianRand() * (end - start + 1));
  }

  //TODO: Switch from useState to useReducer -> https://reactjs.org/docs/hooks-reference.html#usereducer
  const handleOctaveUpClick = () => {
    if (baseOctave < 7){
      setBaseOctave(baseOctave+1);
    }
  }

  const handleOctaveDownClick = () => {
    if (baseOctave > 0) {
      setBaseOctave(baseOctave-1);
    }
  }

  return (
    <div className="h-[84%] pt-4 pb-4 mr-4 ml-4 border-t-4 border-b-4" style={colorSet.borderStyleObj}>
      <div className="absolute top-8 left-1/2 z-10">
        <TinyButton label="M" tip="Mute/unmute" onClick={handleMuteClick}></TinyButton>
        <TinyButton label="C" tip="Clear" onClick={handleClearClick}></TinyButton>
        <TinyButton label="&#8594;" tip="Shift right" onClick={handleShiftRightClick}></TinyButton>
        <TinyButton label="&#8593;" tip="Shift up" onClick={handleShiftUpClick}></TinyButton>

        <TinyButton label="&#8595;" tip="Octave down" onClick={handleOctaveDownClick}></TinyButton>
        <span className="text-xs font-bold">{ baseOctave }</span>
        <TinyButton label="&#8593;" tip="Octave up" onClick={handleOctaveUpClick}></TinyButton>

        <TinyButton label="R" tip="Randomize" onClick={fillRandomly}></TinyButton>
        <TinyButton label="A" tip="Arpeggio" onClick={fillWithArpeggio}></TinyButton>
      </div>
      <div className="h-1/2">
        <StepSequencerComponent baseColor={props.baseColor} channels={props.channels} steps={props.steps} matrix={matrix} setMatrix={setMatrix} monophonic={props.monophonic ? true : false}></StepSequencerComponent>
      </div>
      <div className="h-1/2">
        <div className="h-1/2 flex flex-row">
          <div className="grid grid-flow-col auto-cols-auto w-full mt-4">
            { props.synth !== SynthTypes.Player &&
            <>
              <div>
                <CustomRangeSlider label="Attack" baseColor={props.baseColor} enableFill={true} tip={amplitudeEnvelope.attack} min={0.001} max={1.0} step={0.001} value={amplitudeEnvelope.attack} onChange={ (val: number) => handleSynthParameterChange({attack: Number(val)}) }></CustomRangeSlider>
              </div>
              <div>
                <CustomRangeSlider label="Decay" baseColor={props.baseColor} enableFill={true} tip={amplitudeEnvelope.decay} min={0.001} max={1.0} step={0.001} value={amplitudeEnvelope.decay} onChange={ (val: number) => handleSynthParameterChange({decay: Number(val)}) }></CustomRangeSlider>
              </div>
              <div>
                <CustomRangeSlider label="Sustain" baseColor={props.baseColor} enableFill={true} tip={amplitudeEnvelope.sustain} min={0.001} max={1.0} step={0.001} value={amplitudeEnvelope.sustain} onChange={ (val: number) => handleSynthParameterChange({sustain: Number(val)}) }></CustomRangeSlider>
              </div>
              <div>
                <CustomRangeSlider label="Release" baseColor={props.baseColor} enableFill={true} tip={amplitudeEnvelope.release} min={0.001} max={1.0} step={0.001} value={amplitudeEnvelope.release} onChange={ (val: number) => handleSynthParameterChange({release: Number(val)}) }></CustomRangeSlider>
              </div>
            </>
            }
          </div>
        </div>
        <div className="h-1/2 flex flex-row">
          <div className="grid grid-flow-col auto-cols-auto w-full mt-4">
            { props.synth !== SynthTypes.Player &&
              <div>
                <CustomRangeSlider label="Oscillator" baseColor={props.baseColor} tip={Object.keys(OscillatorTypes)[oscillatorType]} enableFill={false} min={0} max={Object.keys(OscillatorTypes).length-1} step={1} value={0} onChange={ (val: number) => { setOscillatorType(val) } }></CustomRangeSlider>
              </div>
            }
            { props.monophonic && 
              <div>
                <CustomRangeSlider label="Portamento" baseColor={props.baseColor} enableFill={true} tip={portamento} min={0} max={0.25} step={0.01} value={0} onChange={ (val: number) => { setPortamento(val) } }></CustomRangeSlider>
              </div>
            }
            { enableWetness &&
              <div>
                <CustomRangeSlider label="Wetness" baseColor={props.baseColor} enableFill={true} tip={ Math.round(wetness * 100) + "%"} min={0.0} max={1.0} step={0.01} value={wetness} onChange={ (val: number) => { setWetness(val) } }></CustomRangeSlider>
              </div>
              }
            <div>
              <CustomRangeSlider label="Volume" baseColor={props.baseColor} enableFill={true} tip={ Math.round(volume * 100) + "%"} min={0.0} max={1.2} step={0.01} value={volume} onChange={ (val: number) => { setVolume(val) } }></CustomRangeSlider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SynthStepSequencerModule;
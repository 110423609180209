import { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import ColorSystem from '../../assets/js/ColorSystem';
import notes from '../../Notes.md';

interface ModuleProps {
  baseColor: string
}

const NotesModule = (props:ModuleProps) => {
  const colorSet = new ColorSystem(props.baseColor).getColors();
  const [notesMarkdown, setNotesMarkdown] = useState(""); 

  useEffect(() => {
    fetch(notes)
      .then((response) => response.text())
      .then((text) => {
        setNotesMarkdown(text);
      });
  }, []);

  return (
    <div className="h-[84%] pt-4 pb-4 mr-4 ml-4 border-t-4 border-b-4 overflow-y-auto" style={colorSet.borderStyleObj}>
      <div className="prose prose-sm">
        <ReactMarkdown children={notesMarkdown} remarkPlugins={[remarkGfm]} />
      </div>
    </div>
  );
}

export default NotesModule;
import React from 'react';
import ColorSystem from '../assets/js/ColorSystem';

interface ModuleHeaderProps {
  children: string,
  baseColor: string
}

const ModuleHeader = (props:ModuleHeaderProps) => {
  const cs = new ColorSystem(props.baseColor);
  const foregroundColor = cs.foregroundColor().hex();
  const accentColor = cs.accentColor().hex();

  const styleObj = {
    borderColor: foregroundColor,
    color: foregroundColor
  }

  return (
    <div className="relative h-[13%] z-10 select-none" style={styleObj}>
      <div className="flex flex-row text-5xl tracking-tighter p-4">
        <div className="relative basis-1/4 border-t-4" style={styleObj}>
          <div className="relative top-1 ml-1 mr-2 max-h-11">OOF</div>
        </div>
        <div className="relative basis-1/4 border-t-4 border-x-4" style={styleObj}>
          <div className="absolute top-1 ml-1 text-sm uppercase font-bold tracking-tight">Unit</div>
          <div className="absolute top-1 ml-1 mr-2 right-0 text-right max-h-11">{props.children}</div>
        </div>
        {/* <div className="relative basis-1/4 border-t-4 border-r-4" style={styleObj}>
          <div className="absolute top-1 ml-1 text-sm uppercase font-bold tracking-tight">PROG</div>
          <div className="absolute top-1 ml-1 mr-2 right-0 text-right max-h-11">{props.position}</div>
        </div> */}
        <div className="relative basis-1/2 border-t-4 z-0" style={styleObj}>
          <div className="absolute top-1 ml-1 text-sm uppercase font-bold tracking-tight"></div>
          <div className="absolute top-1 ml-1 mr-2 right-0 text-right max-h-11"></div>
        </div>
      </div>
    </div>
  );
}

export default ModuleHeader;
import chroma from "chroma-js"

export default class ColorSystem {
  baseColorHexString: string = '';

  constructor(base: string = "#ffe5e5"){
    this.baseColorHexString = base;
  }

  baseColor = () => {
    return chroma(this.baseColorHexString);
  }

  foregroundColor = () => {
    let fg = chroma([Math.abs(chroma(this.baseColorHexString).hsv()[0]-180), 1, 0.2], 'hsv');
    return fg;
  }

  accentColor = () => {
    //@ts-ignore
    let acc = chroma([this.foregroundColor().hsv()[0]/2, 1, 1], 'hsv');
    return acc;
  }

  getColors = () => {
    const baseColor = this.baseColor().hex()
    const foregroundColor = this.foregroundColor().hex();
    const accentColor = this.accentColor().hex();

    let obj = {
      baseColor: baseColor,
      backgroundColor: baseColor,
      foregroundColor: foregroundColor,
      accentColor: accentColor,
      white: '#ffffff',
      black: '#000000',
      borderStyleObj: {
        borderColor: foregroundColor
      }
    }

    return obj;
  }
}